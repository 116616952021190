<template>
  <header class="helsinki">
    <div> <!-- top row -->
      <!-- breadcrumb -->
      <menu class="breadcrumb">
        <ul><a href="/hcplanning" class="bubble">Headcount Planning</a></ul>
      </menu>

    </div>
    <div> <!-- bottom row -->
      <!-- tab navigation -->
      <nav>
        <div :class="{active: viewedPage === 'plans'}">
          <button class="btn-new subtle" @click="viewedPage = 'plans'">Plans</button>
        </div>
        <div :class="{active: viewedPage === 'approvals'}">
          <button class="btn-new subtle" @click="loadApprovals">
            Approvals <span v-if="pendingApprovals" class="badge">{{ pendingApprovals }}</span>
          </button>
        </div>
      </nav>

      <!-- view toolbar: plans -->
      <div v-if="viewedPage === 'plans'" class="toolbar">
        <button class="btn-new" v-if="hcpPermissions.can_admin_all" @click="$router.push('/hcplanning/admin')">
            <i class="fa-solid fa-cog"></i> Admin</button>
        <button class="btn-new outline" v-if="hcpPermissions.can_export_all" @click="sendCSVExportRequest()"><i class="fa-solid fa-file-csv"></i>Export all plans</button>
      </div>
      <div v-if="viewedPage === 'approvals'" class="toolbar">
        <SearchBox placeholder="Filter approvals" v-model="filter.fulltext" />
      </div>
      <div v-if="viewedPage === 'approvals'"></div>
    </div>
  </header>
  <main class="helsinki" v-if="viewedPage == 'plans'">
    <h4 class="subtitle" v-if="allPlans">Main plans</h4>
    <table class="data-table" v-if="allPlans">
      <thead>
        <tr class="data-header">
          <th style="width: 250px;">Title</th>
          <th style="width: 250px;">Category</th>
          <!-- <th style="width: 250px;">Owner</th> -->
          <th style="width: 250px;">Sup Org</th>
          <th>Status</th>
        </tr>
      </thead>

      <tbody v-if="allPlans.length">
        <tr class="data-row" v-for="row in allPlans" :key="row.review_id" :class="{'isdynamic': row.is_dynamic}">
          <td><RouterLink :to="`/hcplanning/${row.id}`">{{ row.title }}</RouterLink></td>
          <td>{{ row.category }}</td>
          <!-- <td>{{ (row.owner_first_name || "") + " " + (row.owner_last_name || row.owner || "") }}</td> -->
          <td>{{ (row.sup_orgs || []).map(x=>x[1]).join(', ') }}</td>
          <td v-if="row.status != 10" style="color: gray;font-style: italic;">This plan is not active and is only visible to admins</td> 
          <td v-else-if="row.is_dynamic">This plan is dynamic positions</td>
          <td v-else></td><!-- TODO {{ row.status }} -->
        </tr>
      </tbody>
      <tr v-else><td colspan="4">No plans found!</td></tr>
    </table>

    <h4 class="subtitle" v-if="views">Views</h4>
    <table class="data-table" v-if="views">
      <thead>
        <tr class="data-header">
          <th style="width: 250px;">Title</th>
        </tr>
      </thead>

      <tbody>
        <tr class="data-row" v-for="row in views" :key="row.review_id" :class="{'isdynamic': row.is_dynamic}">
          <td><RouterLink :to="`/hcplanning/${row.id}`">{{ row.title }}</RouterLink></td>
        </tr>
      </tbody>
    </table>

  </main>
  <main class="helsinki" v-if="viewedPage == 'approvals'">
    <Approvals :approvals="aggrApprovals" can_approve_position="true" :filter="filter"></Approvals>
  </main>
</template>

<script>
  import { fetchFromApi, downloadFileFromApi, formatDate } from "@/services/AppTools";
  import { permissions } from "@/services/Permissions";
  import Approvals from '@/views/hcplanning/components/Approvals.vue'
  import SearchBox from '@/views/hcplanning/components/SearchBox.vue';

  export default {
    name: "HcAdminView",
    components: {
      Approvals,
      SearchBox
    },

    data() {
      return {
        viewedPage: "plans",
        allPlans: null,
        views: null,
        aggrApprovals: null,
        pendingApprovals: 0,

        filter: {
          fulltext: null,
        },

        permissions,
        hcpPermissions: {}
      }
    },
    methods: {
      async sendCSVExportRequest() {
        let url = `/api/hcp/plans/-1/csvexport`; // -1 for all plans
        let responseBlob = await downloadFileFromApi(this, url);

        let filename = `plan-all-export-${formatDate(Date())}.csv`;
        let blob = new Blob([responseBlob], { type: 'text/csv' });
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = filename;
        link.click();
      },
      async loadApprovals() {
        this.viewedPage = 'approvals'
        let allPositions = await fetchFromApi("GET", `/api/hcp/myapprovals`);
  
        // JSON parse all extra_data fields in positions
        // ignore if extra_data is null
        allPositions.forEach(pos => {
          pos.extra_data = pos.extra_data ? JSON.parse(pos.extra_data) : {};
          pos.sr_data = pos.sr_data ? JSON.parse(pos.sr_data) : {};
        });

        this.aggrApprovals = allPositions.filter(pos => pos.worker == null);
      }
    },
    async beforeMount() {
      this.$root.appClass["page-helsinki"] = true;
      const data = await fetchFromApi("GET", "/api/hcp/plans");
      this.allPlans = data.plans;
      this.views = data.views;
      this.hcpPermissions = data.permissions;
    },
    async beforeUnmount() {
      this.$root.appClass["page-helsinki"] = false;
    },
  }
</script>

<style lang="scss" scoped>
  // @import "~@/styles/helsinki.scss"; // import theme

  h4.subtitle {
    font-weight: 900;
    font-size: 15px;
    margin-bottom: 1ex;
    margin-top: 4em;
    text-transform: uppercase;
    padding-bottom: 2px;
  }

  .data-table {
    margin-top: 0ex;
    td {
      height: 53px;
      box-sizing: border-box;
    }
    tr.isdynamic {
      color: #607D8B;
      font-style: italic;
    }
  }

</style>