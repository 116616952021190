<template>
  <!-- Plan and Approvals view -->
  <div class="positions-table" v-if="approvals !== null" :class="{'largeheader': double_row_header_mode}">
    <div class="pos-table-data">
      <table class="data-table" :set="ppos = applyFilter(approvals)"  ref="positionsDataTable">
        <thead>
          <tr class="data-header">
            <th :class="sortClass('position_id')" @click="setSort('position_id')">Position ID</th>
            <th :class="sortClass('plan')" @click="setSort('plan')" v-if="!no_plan_column">Plan</th>
            <th :class="sortClass('approval')" @click="setSort('approval')">Approval</th>
            <th class="sr-synced" :class="sortClass('sr_status')" @click="setSort('sr_status')" title="Smartrecruiters Status">Status</th>
            <th :class="sortClass('target_start')" @click="setSort('target_start')">Target Start<br> Date</th>
            <th :class="sortClass('extra_data.estimated_start')" @click="setSort('extra_data.estimated_start')">Estimated Start<br> Date</th>
            <th class="job-title" :class="sortClass('extra_data.job_title')" @click="setSort('extra_data.job_title')">Job Title</th>
            <th class="job-location" :class="sortClass('job_profile_name')" @click="setSort('job_profile_name')">Job Profile</th>
            <th class="job-location" :class="sortClass('job_location_name')" @click="setSort('job_location_name')">Job location /<br>Job country</th>
            <th :class="sortClass('extra_data.fte')" @click="setSort('extra_data.fte')">FTE (%)</th>
            <th class="hiring-manager" :class="sortClass('hiring_manager_name')" @click="setSort('hiring_manager_name')">Hiring Manager</th>
            <th :class="sortClass('recruiter')" @click="setSort('recruiter')">Recruiter</th>
            <th :class="sortClass('extra_data.is_backfill')" @click="setSort('extra_data.is_backfill')">Backfill</th>
            <th :class="sortClass('extra_data.backfill')" @click="setSort('extra_data.backfill')">Backfill for</th>
            <th class="job-location" :class="sortClass('extra_data.cost_center')" @click="setSort('extra_data.cost_center')">Cost Center</th>
            <th class="job-location">Hiring Mananger Comments</th>
            <th class="job-location">Approver Comments</th>
            <th :class="sortClass('created')" @click="setSort('created')" colspan="2">Position<br>Created</th>
          </tr>
        </thead>
        <tbody v-if="ppos && ppos.length">
          <tr class="data-row" v-for="pos in ppos" :key="pos.id" :data-id="pos.id" :class="pos._class">
            
            <td class="pid">{{ pos.position_id }}</td>
            <td v-if="!no_plan_column">{{ pos.plan_name }}</td>
            <!-- approval status -->
            <td>{{ approvalToWord(pos.approval) }}</td>
            <td>
              <a v-if="pos.extra_data.job_url" :href="pos.extra_data.job_url" target="_blank">{{ statusToWord(pos.sr_status) }}</a>
              <span v-else>{{ statusToWord(pos.sr_data.sr_status) }}</span>
            </td>
            <td>{{ formatDate(pos.planned_quarter) }}</td><!-- TODO: rename underlying field -->
            <td>{{ formatDate(pos.extra_data ? pos.extra_data.estimated_start : '') }}</td>
            <td>{{ pos.extra_data ? pos.extra_data.job_title : '' }}</td>
            <td class="job-profile">{{ pos.job_profile_name }}</td>
            <td>{{ pos.job_location_name }}</td>
            <td>{{ pos.extra_data.fte }}</td>
            <td>{{ pos.hiring_manager_name }}</td>
            <td>{{ pos.recruiter_name }}</td>
            <td :class="{'required':!pos.extra_data.is_backfill || pos.extra_data.is_backfill == ''}">{{ pos.extra_data.is_backfill }}</td>
            <td>{{ pos.extra_data.backfill_name }}</td>
            <td>{{ pos.extra_data.cost_center_name }}</td>
            <td class="comments"><CommentPopup :text="pos.extra_data.comments"/></td><!-- approver comments -->
            <td class="comments"><CommentPopup :text="pos.extra_data.approver_comments"/></td><!-- position created  -->
            <td class="sr-synced">{{ formatDateTime(pos.created) }}</td>

            <!-- actions -->
            <td class="actions-td" style="background: white">
              <button class="btn-new subtle slim" @click="submitForApprovalDialog = pos;" 
                :disabled="pos.approval !== 0" v-if="can_approve_position">Approve/reject</button>
              <button title="View history" @click="historyDialog=pos" class="btn-new subtle-icon compact">
                <i class="fa-solid fa-clock"></i></button>
            </td>
          </tr>
        </tbody>
        <tr v-else>
          <td colspan="4" class="no-subplans-notice">No approvals pending.</td>
        </tr>
      </table>
    </div>
  </div>

  <ModalDialog :title="'Approve/reject position: ' + submitForApprovalDialog.position_id" v-if="submitForApprovalDialog" @close="submitForApprovalDialog=null">
    <!-- <p style="font-size: 15px">Update your position comment/justification for the approver:</p> -->

    <div style="font-size: 11px;font-weight: bold;">HIRING MANANGER COMMENTS</div>
    <textarea v-model="submitForApprovalDialog.extra_data.comments" focus style="font-size: 15px; font-family: Roboto;width: 100%; height: 15em;" disabled></textarea>

    <div style="font-size: 11px;font-weight: bold;">APPROVER COMMENTS</div>
    <textarea v-model="submitForApprovalDialog.extra_data.approver_comments" focus style="font-size: 15px; font-family: Roboto;width: 100%; height: 15em;"></textarea>

    <template v-slot:buttons>
      <button class="btn-new btn-approve" :disabled="this.$root.loading" @click="approvePosition(submitForApprovalDialog, $event)">Approve</button>
      <button class="btn-new btn-reject" :disabled="this.$root.loading" @click="rejectPosition(submitForApprovalDialog, $event)">Reject</button>
      <button class="btn-new btn-reject" :disabled="this.$root.loading" @click="sendPositionBackToManager(submitForApprovalDialog, $event)">Reject, back to hiring manager</button>
      <button class="btn-new outline" :disabled="this.$root.loading" @click="submitForApprovalDialog=null">Cancel</button>
    </template>
  </ModalDialog>

  <ModalDialog title="Position history" v-if="historyDialog" @close="historyDialog=null" class="hcp-history-modal">
    <PositionHistory :planId="historyDialog.plan" :positionId="historyDialog.id"> </PositionHistory>

    <template v-slot:buttons>
      <button class="btn-new outline" @click="historyDialog=null">Close</button>
    </template>
  </ModalDialog>

  <div class="undo-approval-overlay" v-if="undoApproval">
    <div v-for="approval in undoApproval" :key="approval.id">
      {{ approval.title }} <button class="btn-new outline" @click="undoApprovalPosition(approval.pos)">Undo</button>
    </div>
  </div>
</template>

<script>
  import { fetchFromApi, postToApi, putToApi, deleteFromApi, downloadFileFromApi, formatDate, formatDateTime } from '@/services/AppTools.js';
  import CommentPopup from '@/views/hcplanning/components/CommentPopup.vue';
  import PositionHistory from '@/views/hcplanning/PlanHistoryView.vue'
  import ModalDialog from '@/components/ModalDialog.vue'

  export default {
    name: "ApprovalsComponent",
    props: ["approvals", "can_approve_position", "filter", "double_row_header_mode", "no_plan_column"],
    components: {
      CommentPopup,
      PositionHistory,
      ModalDialog,
    },
    data() {
      return {
        sortcolumn: "position_id",
        sortOrder: 1,

        // modal dialogs
        showCommentPopup: false,
        undoApproval: [],
        historyDialog: null,
        submitForApprovalDialog: null,

        formatDate: formatDate, 
        formatDateTime: formatDateTime,
      };
    },
    methods: {
      sortClass(field) {
        if (this.sortcolumn === field) {
          if (this.sortOrder === 1)
            return ["sortable", "asc"];
          return ["sortable", "desc"];
        }
        return "sortable";
      },
      setSort(field) {
        if (this.sortcolumn === field) {
          this.sortOrder *= -1;
        } else {
          this.sortcolumn = field;
          this.sortOrder = 1;
        }

        //this.filteredPlanPositions = this.applyFilter(this.planPositions);
      },
      applyFilter(positions) {
        let filtered = null;

        // if (this.viewedPage == "approvals") {
          filtered = positions.filter(pos => {
            if (!(pos.approval === 0 && pos.position_id)) {
              return false;
            }

            if (this.filter.fulltext) {
              for (const part of this.filter.fulltext.toLowerCase().split(" ")) {
                let found = false;
                for (const [key, value] of Object.entries(pos)) {
                  if(typeof value === 'string' && value.toLowerCase().includes(part)){
                    found = true;
                    break;
                  }
                }
                if(!found) {
                  for (const [key, value] of Object.entries(pos.extra_data)) {
                    if(typeof value === 'string' && value.toLowerCase().includes(part)){
                      found = true;
                    }
                  }
                }
                if(!found){
                  return false;
                }
              }
            }

            return true;
          });

        // sort data
        if (!this.sortcolumn){
          return filtered;
        }

        if(this.sortcolumn.includes("extra_data")) {
          let extraDataField = this.sortcolumn.split(".")[1];
          return filtered.sort((a, b) => {
            if (a._class == "unsaved") return 1; // new items are always at the bottom
            if (a.extra_data[extraDataField] === undefined || a.extra_data[extraDataField] === null || a.extra_data[extraDataField] == "") return this.sortOrder;
            
            if (b._class == "unsaved") return 1; // new items are always at the bottom
            if (b.extra_data[extraDataField] === undefined || b.extra_data[extraDataField] === null || b.extra_data[extraDataField] == "") return -this.sortOrder;

            return a.extra_data[extraDataField] > b.extra_data[extraDataField] ? 1 * this.sortOrder : -1 * this.sortOrder;
          });
        }

        return filtered.sort((a, b) => {
          if (a._class == "unsaved") return 1; // new items are always at the bottom
          if (a[this.sortcolumn] === null || a[this.sortcolumn] == "") return this.sortOrder;
          if (b._class == "unsaved") return 1; // new items are always at the bottom
          if (b[this.sortcolumn] === null || b[this.sortcolumn] == "") return -this.sortOrder;
          return a[this.sortcolumn] > b[this.sortcolumn] ? 1 * this.sortOrder : -1 * this.sortOrder;
        });
      },
      approvalToWord(approvalStatus) {
        if (approvalStatus === 1) return "Approved";
        if (approvalStatus === 0) return "Pending";
        if (approvalStatus === -1) return "Rejected";
        return "Unknown";
      },

      // Smart recruiter status
      statusToWord(status) {
        const MAP = ["NOT STARTED", "CREATED", "SOURCING", "INTERVIEW", "OFFER", "FILLED", "ON_HOLD", "CANCELLED"]
        return MAP[status] || "NOT STARTED";
      },

      async loadData() {
        return await fetchFromApi(this, this.dataUrl, data => data);
      },

      // postion approval
      async undoApprovalPosition(pos) {
        let url = `/api/hcp/plans/${pos.plan}/positions/${pos.id}/approval`;
        await postToApi(this, url, null, {approval_status: 0});
        pos.approval = 0;

        const index = this.undoApproval.findIndex(x=>x.id == pos.id);
        if (index > -1) { // only splice array when item is found
          this.undoApproval.splice(index, 1); // 2nd parameter means remove one item only
        }
      },
      async approvePosition(pos, event) {
        // update approver comment
        let positions = [{ ...pos }]; // clone position so we can safely stringify the extradata
        positions.forEach(pos => {
          pos.extra_data = JSON.stringify(pos.extra_data);
        });
        await putToApi(this, `/api/hcp/plans/${pos.plan}/positions`, positions);

        // event.target.closest("tr").classList.toggle("animate-approved")
        const item = {title: pos.position_id + " approved", id: pos.id, pos: pos};
        await this.posDecision(pos, 1, item); // 1 = APPR_APPROVED
        this.submitForApprovalDialog = null;
      },
      async rejectPosition(pos, event) {
        // update approver comment
        let positions = [{ ...pos }]; // clone position so we can safely stringify the extradata
        positions.forEach(pos => {
          pos.extra_data = JSON.stringify(pos.extra_data);
        });
        await putToApi(this, `/api/hcp/plans/${pos.plan}/positions`, positions);

        // event.target.closest("tr").classList.toggle("animate-rejected")
        const item = {title: pos.position_id + " rejected", id: pos.id, pos: pos};
        await this.posDecision(pos, -1, item); // -1 = APPR_REJECTED
        this.submitForApprovalDialog = null;
      },      
      async sendPositionBackToManager(pos, event) {
        pos.extra_data.hint = "Position rejected and sent back to hiring manager";
        // update approver comment
        let positions = [{ ...pos }]; // clone position so we can safely stringify the extradata
        positions.forEach(pos => {
          pos.extra_data = JSON.stringify(pos.extra_data);
        });
        await putToApi(this, `/api/hcp/plans/${pos.plan}/positions`, positions);

        const item = {title: pos.position_id + " rejected", id: pos.id, pos: pos};
        await this.posDecision(pos, 10, item); // 10 = APPR_NOT_REQUESTED
        this.submitForApprovalDialog = null;
      },
      async posDecision(pos, decision, item) {
        this.undoApproval.push(item);
        if(this.undoApproval.length > 3) {
          this.undoApproval.shift();
        }
        
        // remove element after 5 seconds
        setTimeout(() => {
            const index = this.undoApproval.findIndex(x=>x.id == pos.id);
            if (index > -1) { // only splice array when item is found
              this.undoApproval.splice(index, 1); // 2nd parameter means remove one item only
            }
          }, 15000);
        setTimeout(() => {pos.approval = decision;}, 300);

        await postToApi(this, `/api/hcp/plans/${pos.plan}/positions/${pos.id}/approval`, null, {approval_status: decision});
        pos.approval = decision;
      },
    },
  };
</script>
  
<style lang="scss" scoped>
  .data-table {
    margin-top: 0;
  }
  .data-table th {
    position: sticky;
    top: calc(var(--header-height) + 48px);
    background: white;
    min-width: 100px;
    line-height: 1.1em;
    padding-top: 3.5em;
    height: 2.2em;
  }
    
  .largeheader {
    margin-top: 39px;
    .data-table th {
      top: calc(var(--header-height) + 91px);    
    }
  }

  .data-table td {
    background: none;
    min-width: 100px;
    height: 53px;
    box-sizing: border-box;
    padding-left: 4px;
    padding-right: 4px;
  }

  .job-location, .hiring-manager, .job-title {
    min-width: 200px!important;
  }

  .sortable {
    user-select: none;
    word-break: keep-all;
    cursor: pointer;
    &::after {
      content: ' ↓↑';
      visibility: hidden;
    }
  
    &:hover::after {
      content: ' ↓↑';
      visibility: visible;
    }
    &.asc::after {
      content: ' ↓';
      visibility: visible;
    }
    &.desc::after {
      content: ' ↑';
      visibility: visible;
    }
  }

  .actions-th, .actions-td {
    position: sticky!important;
    right: 0;
    // background-color: #f8f8f8!important;
    // background-color: rgba(255, 255, 255, 0.8)!important;
    text-align: center;
    text-wrap: nowrap;
    border-top: 1px solid #eee;
    border-bottom: 0;
    margin-top: -1px;

    // .btn-new.subtle-icon {
    //   // background-color: #f8f8f8!important;
    // }
  }
  .actions-th {
    min-width: 3em;
    background: white!important;
  }
  .actions-td {
    padding: 0;
    display: flex;
    justify-content: right;
    align-items: center;
  }

  th.sr-synced {
    text-shadow: 0px 0px 2px rgba(0,255,0,0.5);
  }

  // approval
  .btn-approve {
    color: #2ecc71;
    border: 1px solid #2ecc71;
    background: #fff;
  }
  .btn-reject {
    color: #e74c3c;
    border: 1px solid #e74c3c;
    background: #fff;
  }

  .btn-reject:disabled,
  .btn-approve:disabled {
    color: #ccc;
  }

  .animate-approved {
    animation: approve-flash 0.2s ease-in-out;
    opacity: 0;
  }

  .animate-rejected {
    animation: reject-flash 0.2s ease-in-out;
    opacity: 0;
  }

  @keyframes approve-flash {
    0% { opacity: 1; } 
    50% {
      background-color: hsla(145, 63%, 49%, 0.6);
      opacity: 1;
    } 
    100% { opacity: 0; }
  }

  @keyframes  reject-flash {
    0% { opacity: 1; } 
    50% {
      background-color: hsla(14, 63%, 49%, 0.6);
      opacity: 1;
    } 
    100% { opacity: 0; }
  }

  // undo approval
  .undo-approval-overlay {
    position: fixed;
    bottom: 1ex;
    left: 50%;
    transform: translateX(-50%);
    font-size: 15px;

    div {
      background-color: #fafafa;
      border-radius: 70px;
      padding: 1ex 2em;
      box-shadow: 1px 3px 10px rgba(0, 0, 0, 0.15);
      margin-top: 1.5ex;

      animation: undo-item-appear 0.5s ease-in-out;
    }

    button { height: 2em; }
  }


  @keyframes undo-item-appear {
    0% { opacity: 0; transform: translateX(-20px); display: block; } 
    100% { opacity: 1; transform: translateX(0%); }
  }
</style>
